<template>
    <Modal v-model="showForm" title="新增职位" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="80">
            <FormItem label="职位名称" prop="name">
                <Input type="text" :maxlength="40" v-model="addForm.name" placeholder="请输入职位名称"/>
            </FormItem>
            <FormItem label="职位描述" prop="profile">
                <quill-editor v-model="addForm.profile" :options="editorOption"></quill-editor>
            </FormItem>
            <FormItem label="薪资范围" prop="salaryFrom">
                <Row>
                    <Col span="11">
                        <Select v-model="addForm.salaryFrom" @on-change="setSalaryToMax" placeholder="请选择最小薪资"
                                filterable clearable>
                            <Option v-for="item in salaryFromArray" :value="item.id" :key="item.id">{{
                                    item.name
                                }}
                            </Option>
                        </Select>
                    </Col>
                    <Col class="text-center" span="2">至</Col>
                    <Col span="11">
                        <Select v-model="addForm.salaryTo" placeholder="请选择最大薪资" :disabled="salaryToDisabled" filterable
                                clearable>
                            <Option v-for="item in salaryToArray" :value="item.id" :key="item.id">{{
                                    item.name
                                }}
                            </Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="学历要求" prop="education">
                <Select v-model="addForm.education" filterable clearable @on-change="changeEducation" label-in-value>
                    <Option v-for="item in educationArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="招聘人数" prop="recruitingNumbers">
                <Input type="text" @on-keyup="recommend($event)" :maxlength="11" v-model="addForm.recruitingNumbers" placeholder="请输入招聘人数"/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {quillEditor} from 'vue-quill-editor';
// 富文本编辑器外部引用样式  三种样式三选一引入即可
import 'quill/dist/quill.snow.css';
import {checkEditorContent} from "../../../common/publicFuns";
import {reqGetDictionaryListByCode} from "../../../api/system-api";

export default {
    name: "addWcPosition",
    components: {
        quillEditor
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        return {
            editorOption: { //  富文本编辑器配置
                modules:{
                    clipboard: {
                        // 粘贴版，处理粘贴时候带图片
                        matchers: [['img', this.handleCustomMatcher]],
                    },
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ]
                },
                theme: 'snow',
                placeholder: '请输入'
            },
            header: {},
            loading: false,
            showForm: this.value,
            educationArray: [],
            salaryFromArray: [],
            salaryToArray: [],
            salaryToDisabled: false,
            addForm: {
                name: '',
                profile: '',
                salaryFrom: '',
                education: '',
                recruitingNumbers: null
            },
            addFormRule: {
                name: [{required: true, message: '请输入职位名称', trigger: 'blur'}],
                profile: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value || value.length === 0) {
                            return callback(new Error('请输入职位简介'));
                        }
                        if (value && !checkEditorContent(value, 20000)) {
                            return callback(new Error('职位简介不能超过20000字符'));
                        }
                        return callback();
                    }
                }],
                salaryFrom: [{required: true, message: '请选择薪资范围', type: 'integer', trigger: 'blur'}],
                education: [{required: true, message: '请输入学历要求', type: 'integer', trigger: 'change'}],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            if (this.addForm.salaryFrom !== -1 && (this.addForm.salaryTo === null || this.addForm.salaryTo === undefined)){
                this.loading = false;
                this.$Message.error("请正确选择薪资范围")
                return false;
            }
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }

                this.loading = false;
                this.$emit("closeAddPosition",this.addForm);
                this.closeModal(false);
            });
        },
        getWcPositionEducation(){
            reqGetDictionaryListByCode({code: 'wc_position_education'}).then((res) => {
                if (res.data.code === 1){
                    this.educationArray = res.data.data
                }else {
                    this.educationArray = []
                }
            }).catch(() => {
                this.statusList = [];
            })
        },
        changeEducation(value) {
            if (value){
                this.addForm.educationName = value.label
            }
        },
        recommend($event) {
            if ('undefined' !== typeof $event) {
                this.addForm.recruitingNumbers = this.addForm.recruitingNumbers.replace(
                    /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                    '$1'
                )
            }
        },
        // 薪资范围
        setSalaryToMax(val) {
            if (val === -1) {
                this.salaryToDisabled = true;
                this.salaryToArray = [];
                this.addForm.salaryTo = null;
            } else if ('undefined' === typeof val) {
                this.salaryToArray = [];
                for (let i = 1; i <= 200; i++) {
                    this.salaryToArray.push({id: i, name: i + 'K'});
                }

                this.salaryToDisabled = false;
            } else {
                this.salaryToArray = [];
                for (let i = val + 1; i <= 200; i++) {
                    this.salaryToArray.push({id: i, name: i + 'K'});
                }

                this.salaryToDisabled = false;
            }
        },
        buildSalaryArray() {
            this.salaryFromArray = [];
            this.salaryToArray = [];
            this.salaryFromArray.push({id: -1, name: '面议'});
            for (let i = 1; i <= 500; i++) {
                this.salaryFromArray.push({id: i, name: i + 'K'});
                this.salaryToArray.push({id: i, name: i + 'K'});
            }
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.addForm.salaryFrom = null;
                this.addForm.salaryTo = null;
                this.educationArray = [];
                this.buildSalaryArray();
                this.getWcPositionEducation();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
