<template>
    <Modal v-model="showForm" title="职位列表" :mask-closable="false" width="800">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">企业信息</div>
            <FormItem label="企业名称">{{ viewForm.name }}</FormItem>
            <FormItem label="企业标识">
                <div class="upload-image-list">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
            <FormItem label="企业简介"><div v-html="viewForm.profile"></div></FormItem>
            <div class="divider">职位信息</div>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入职位名称" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="doSearching">搜索</Button>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" icon="md-add" @click="handleAdd">新增</Button>
                    </FormItem>
                </Form>
            </div>
            <div class="table">
                <div class="table-list">
                    <Table stripe :columns="positionColumns" :data="copyList" :loading="positionLoading">
                        <template slot-scope="{ row, index }" slot="action">
                            <a @click="handleView(row, index)">详情</a>
                            <Divider type="vertical"/>
                            <a @click="handleEdit(row, index)">编辑</a>
                            <Divider type="vertical"/>
                            <a class="error" @click="handleDel(row, index)">刪除</a>
                        </template>
                    </Table>
                </div>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imgData" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <add-position v-model="showAddModal" :id="id" v-on:closeAddPosition="closeAddPosition"></add-position>
        <view-position v-model="showViewModal" :data="data"></view-position>
        <edit-position v-model="showEditModal" :data="data" v-on:closeEditPosition="closeEditPosition"></edit-position>
    </Modal>
</template>
<script>
import {SERVER_HOST} from "@/common/portConfig";
import {
    reqGetPositionList,
    reqGetWcCustomerById, reqUpdateWcPosition
} from "../../../api/admin-api";
import addPosition from "./positionAdd.vue"
import viewPosition from "./positionView.vue"
import editPosition from "./positionEdit.vue"

export default {
    name: "positionList",
    components: {
        addPosition,
        viewPosition,
        editPosition
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            pid: '',
            data: {},
            filter: {
                keywords: ''
            },
            showPreview: false,
            imgData: null,
            loading: false,
            positionLoading: false,
            showAddModal: false,
            showViewModal: false,
            showEditModal: false,
            showForm: this.value,
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                positionList: false,
            },
            deleteName: [],
            positionList: [],
            updatePositionList: [],
            delList: [],
            copyList: [],
            viewForm: {},
            viewFormRule: {},
            positionColumns: [
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '薪资范围',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.salaryFrom === -1 ? '面议' : params.row.salaryFrom + 'K - ' + params.row.salaryTo + 'K'
                        )
                    },
                },
                {
                    title: '学历要求',
                    key: 'educationName',
                    tooltip: 'true',
                    width: 120,
                },
                {
                    title: '招聘人数',
                    key: 'recruitingNumbers',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 180,
                    align: 'center',
                    fixed: 'right',
                },
            ]
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true
            let editPositionList = []
            let addPositionList = []
            for (let i = 0; i < this.positionList.length; i++) {
                this.positionList[i].recruitingNumbers = Number(this.positionList[i].recruitingNumbers)
                if (!this.positionList[i].createBy){
                    addPositionList.push(this.positionList[i])
                }else {
                    editPositionList.push(this.positionList[i])
                }
            }
            let addForm = {}
            addForm.editPositionList = editPositionList
            addForm.addPositionList = addPositionList
            addForm.delList = this.delList
            reqUpdateWcPosition(addForm).then((res)=>{
                this.loading = false;
                if (res.data.code === 1){
                    this.$Message.success('添加成功');
                    this.$emit('getCustomer');
                    this.closeModal(false);
                }else {
                    this.$Message.error('添加失败');
                }
            }).catch(()=>{
                this.loading = false;
                this.$Message.error('添加失败');
            })
        },
        closeAddPosition(val) {
            this.positionList.push({
                customerId: this.id,
                name: val.name,
                profile: val.profile,
                salaryFrom: val.salaryFrom,
                salaryTo: val.salaryTo,
                education: val.education,
                educationName: val.educationName,
                recruitingNumbers: val.recruitingNumbers,
                isRecommended: 0
            })
            this.copyList = this.positionList;
        },
        closeEditPosition(val) {
            this.positionList.splice(val.index, 1)
            if (val.createTime !== undefined){
                this.positionList.push({
                    id: val.id,
                    customerId: this.id,
                    name: val.name,
                    profile: val.profile,
                    salaryFrom: val.salaryFrom,
                    salaryTo: val.salaryTo,
                    education: val.education,
                    educationName: val.educationName,
                    recruitingNumbers: val.recruitingNumbers,
                    isRecommended: val.isRecommended,
                    createBy: val.createBy,
                    createTime: val.createTime,
                })
            }else {
                this.positionList.push({
                    id: val.id,
                    customerId: this.id,
                    name: val.name,
                    profile: val.profile,
                    salaryFrom: val.salaryFrom,
                    salaryTo: val.salaryTo,
                    education: val.education,
                    educationName: val.educationName,
                    recruitingNumbers: val.recruitingNumbers,
                    isRecommended: val.isRecommended
                })
            }
            this.copyList = this.positionList;
        },
        getWcCustomerById() {
            reqGetWcCustomerById({id: this.id}).then((res)=>{
                if (res.data.code ===1){
                    this.viewForm = res.data.data
                    this.imgData = SERVER_HOST + '/resource/public/' + res.data.data.currentLogoName;
                }else {
                    this.viewForm = {}
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        getPositionList(){
            this.positionLoading = true
            reqGetPositionList({customerId: this.id,keywords : this.filter.keywords,isRecommended : null}).then((res) => {
                this.positionLoading = false
                this.positionList = res.data.data;
                this.copyList = this.positionList
            }).catch((err) => {
                this.positionList = [];
                this.positionLoading = false;
            });
        },
        doSearching() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.positionList));
                this.copyList = myData.filter(item => (item.name).includes(this.filter.keywords));
            } else {
                this.copyList = JSON.parse(JSON.stringify(this.positionList));
            }
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handlePreview(val) {
            this.showPreview = true;
        },
        handleEdit(row, index) {
            this.data = row
            this.data.index = index
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.data = row
            this.data.imgData = this.imgData
            this.data.customerName = this.viewForm.name
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除职位',
                content: '确定删除职位：'+row.name+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    if (row.id){
                        this.delList.push(row.id)
                    }
                    this.positionList.splice(index, 1)
                    this.copyList = this.positionList
                },
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.filter.keywords = ''
                this.positionList = [];
                this.delList = [];
                this.copyList = [];
                this.getWcCustomerById()
                this.getPositionList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
