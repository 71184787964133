<template>
    <Modal v-model="showForm" title="查看企业" :mask-closable="false" width="800px">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">企业信息</div>
            <FormItem label="企业名称">{{ viewForm.name }}</FormItem>
            <FormItem label="企业标识">
                <div class="upload-image-list">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
            <FormItem label="企业简介"><div class="multiline-content" v-html="viewForm.profile"></div></FormItem>
            <FormItem label="更新人">{{ viewForm.updatedByName }}</FormItem>
            <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
            <div class="divider">职位信息</div>
            <Table stripe :columns="positionColumns" :data="positionList" :loading="positionLoading">
                <template slot-scope="{ row, index }" slot="action">
                    <a @click="handleView(row, index)">详情</a>
                </template>
            </Table>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imgData" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
        <view-position v-model="showViewModal" :data="viewData"></view-position>
    </Modal>
</template>
<script>
import {SERVER_HOST} from "@/common/portConfig";
import {reqGetPositionList, reqGetWcCustomerById} from "../../../api/admin-api";
import viewPosition from "./positionView.vue"

export default {
    name: "viewWcCustomer",
    components: {
        viewPosition
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
        data: {}
    },
    data() {
        return {
            pid: '',
            viewData: {},
            showViewModal: false,
            showPreview: false,
            imgData: null,
            loading: false,
            positionLoading: false,
            positionList: [],
            positionColumns: [
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '薪资范围',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.salaryFrom === -1 ? '面议' : params.row.salaryFrom + 'K - ' + params.row.salaryTo + 'K'
                        )
                    },
                },
                {
                    title: '学历要求',
                    key: 'educationName',
                    tooltip: 'true',
                    width: 120,
                },
                {
                    title: '招聘人数',
                    key: 'recruitingNumbers',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '是否推荐?',
                    key: 'isRecommended',
                    width: 100,
                    render: (h, params) => {
                        if (params.row.isRecommended === 0){
                            return h('span' , "否")
                        }else {
                            return h('span' , "是")
                        }
                    },
                    align: 'center'
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                }
            ],
            showForm: this.value,
            deleteName: [],
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        getWcCustomer() {
            reqGetWcCustomerById({id: this.id}).then((res)=>{
                if (res.data.code ===1){
                    this.viewForm = res.data.data
                    this.imgData = SERVER_HOST + '/resource/public/' + res.data.data.currentLogoName
                }else {
                    this.viewForm = {}
                }
            }).catch(()=>{
                this.viewForm = {}
            })
        },
        getPositionList(){
            this.positionLoading = true
            reqGetPositionList({customerId: this.id,keywords : '',isRecommended : null}).then((res) => {
                this.positionLoading = false
                this.positionList = res.data.data;
            }).catch((err) => {
                this.positionList = [];
                this.positionLoading = false;
            });
        },
        handleView(row, index) {
            this.viewData = row
            this.viewData.imgData = SERVER_HOST + '/resource/public/' + row.currentLogoName;
            this.showViewModal = true
        },
        handlePreview(val) {
            this.showPreview = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.getWcCustomer()
                this.getPositionList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
